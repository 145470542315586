import axios from "axios";
import { BASE_URL } from "../../constants/portConstants";
import { Cookies } from "react-cookie";
import { notify } from "../../utlis/handler";
export const cookies = new Cookies();

axios.defaults.baseURL = BASE_URL;
const timeout = 1000000;

export default async function apiRequest(apiParams) {
  const accessToken = await cookies.get("SID");

  if (accessToken)
    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  // axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  axios.defaults.crossDomain = true;
  const parseErrorMessage = (error) => {
    if (error.code === "ECONNABORTED") {
      return "Please check your network connection and try again.";
    }
    return typeof error.message === "object"
      ? Object.values(error.message).join(", ")
      : error.message;
  };
  return await axios({ timeout, ...apiParams })
    .then((response) => {
      let resp = {
        data: response.data
          ? response.data.data
            ? response.data.data
            : response.data.data === null
            ? response.data.data
            : response.data
          : response,
        message: response.data
          ? response.data.data && response.data.data.message
            ? response.data.data.message
            : response.data.message && response.data.message
          : null,
        status: response.status,
        success: response.data.error ? false : true,
      };
      if (resp.data && resp.message && !apiParams.skipNotification) {
        notify(resp.message, "success");
      }

      return resp;
    })
    .catch((error) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log("Error", error.response.data.message);
        if (error.response.data.error) {
          error.response.data.message = error.response.data.error;
        }
        if (
          !apiParams.skipError &&
          (error.response.status === 401 ||
            (error.response.data &&
              error.response.data.message &&
              [
                "jwt malformed",
                "The user does not exist or inactive",
                "User not found!",
              ].includes(error.response.data.message)))
        ) {
          notify(error.response.data.message || "Unauthorized");
          setTimeout(() => {
            cookies.remove("SID");
            // window.location.reload();
            return;
          }, 1000);
        }
        console.log("Error", error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log("Error", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log("Error", error.config);
      console.log("Error", error.toJSON());
      const errorObj =
        error &&
        error.response &&
        error.response.data &&
        error.response.data &&
        error.response.data
          ? error.response.data
          : error.toJSON();
      let message = parseErrorMessage(errorObj);
      if (message) notify(message);
      return {
        ...errorObj,
        message,
        // data: errorObj.data&& errorObj.data. data ? errorObj.data.data : {},
        // name: err.name,
        // stack: exception.stack,
        success: false,
      };
    });
}
