import React from "react";
const Home = React.lazy(() => import("../pages/Homepage/index"));
const Login = React.lazy(() => import("../pages/Login/index"));
const Dashboard = React.lazy(() => import("../pages/Dashboard/index"));
// const CreateOrder = React.lazy(() => import("../pages/CreateOrder/index"));
// const Address = React.lazy(() => import("../pages/Address/Index"));
// const Service = React.lazy(() => import("../pages/Service/index"));
// const Dashboard = React.lazy(() => import("../pages/Dashboard/Index"));
// const OtpLogin = React.lazy(() => import("../pages/OtpLogin"));

// const VerifyOtp = React.lazy(() => import("../pages/VerifyOtp"));
// const CreateDap = React.lazy(() => import("../pages/CreateDap"));

const routes = [
  {
    path: '/',
    exact: false,
    element: Home,
    // isAuthenticated: false,
    isCommon: true
  },
  {
    path: "/login",
    exact: false,
    element: Login,
    isAuthenticated: false,
  },
  
  {
    path: "/dashboard",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  
];

export default routes;
