import { configureStore } from "@reduxjs/toolkit";
import UserReducers from "./user/userReducers";
import gameReducers from "./game/gameReducers";

export const store = configureStore({
  reducer: {
    user: UserReducers,
    game: gameReducers,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
