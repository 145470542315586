import { BrowserRouter } from "react-router-dom";
import Routes from "../src/routes/index";
// import "../src/assets/fonts/DragonHunder-9Ynxj.otf";
import { ToastContainer } from "react-toastify";
import { CookiesProvider } from "react-cookie";
import { Provider } from "react-redux";
import { store } from "./store";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

const App = () => {
  return (
    <Provider store={store}>
          <CookiesProvider>
                <BrowserRouter>
                  <Routes />
                </BrowserRouter>
              <ToastContainer />
          </CookiesProvider>
        </Provider>
  );
};

export default App;
