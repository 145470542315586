import { createSlice } from "@reduxjs/toolkit";
// import { cookies } from "../../services/auth";
import { getProfile } from "./gameActions";
import { getStacks, sendToken, getAllWaxAssets, addStack } from "./gameActions";
const gameSlice = createSlice({
  name: "game",
  initialState: {
    loading: false,
    isLoggedIn: false,
    profile: {
      _id: null,
    },
    userInfo: {
      _id: null,
    },
    staked: [],
    token: null,
    waxData: [],
    stakedData: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    // you can mutate state directly, since it is using immer behind the scenes
    builder
      .addCase(getProfile.pending, (state, action) => {
        state.loading = true;
        state.isLoggedIn = false;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.profile = action.payload.data;
      })
      .addCase(getProfile.rejected, (state, action) => {
        state.loading = false;
        state.isLoggedIn = false;
      })
      .addCase(getStacks.pending, (state, action) => {
        state.loading = true;
        state.isLoggedIn = false;
      })
      .addCase(getStacks.fulfilled, (state, action) => {
        state.loading = false;
        state.staked = action.payload.data?.stakes || [];
      })
      .addCase(getStacks.rejected, (state, action) => {
        state.loading = false;
        state.isLoggedIn = false;
      })
      .addCase(sendToken.pending, (state, action) => {
        state.loading = true;
        state.isLoggedIn = false;
      })
      .addCase(sendToken.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload.data;
      })
      .addCase(sendToken.rejected, (state, action) => {
        state.loading = false;
        state.isLoggedIn = false;
      })
      .addCase(getAllWaxAssets.pending, (state, action) => {
        state.loading = true;
        state.isLoggedIn = false;
      })
      .addCase(getAllWaxAssets.fulfilled, (state, action) => {
        state.loading = false;
        state.waxData = action.payload.data;
      })
      .addCase(getAllWaxAssets.rejected, (state, action) => {
        state.loading = false;
        state.isLoggedIn = false;
      })
      .addCase(addStack.pending, (state, action) => {
        state.loading = true;
        state.isLoggedIn = false;
      })
      .addCase(addStack.fulfilled, (state, action) => {
        state.loading = false;
        state.stakedData = action.payload.data;
      })
      .addCase(addStack.rejected, (state, action) => {
        state.loading = false;
        state.isLoggedIn = false;
      });
  },
});
export default gameSlice.reducer;
