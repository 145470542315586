import { createAsyncThunk } from "@reduxjs/toolkit";
import apiRequest from "../../services/auth";


export const getProfile = createAsyncThunk(
  "user/get",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: "/userinfo",
      method: "GET",
      skipError: true,
    }).then((response) => {
      return response;
    });
    return res;
  }
);


export const logout = createAsyncThunk(
  "user/logout",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: "/logout",
      method: "GET",
      skipError: true,
    }).then((response) => {
      return response;
    });
    return res;
  }
);
