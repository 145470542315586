import { createSlice } from "@reduxjs/toolkit";
import { cookies } from "../../services/auth";
import { getProfile } from "./userActions";

const loginSlice = createSlice({
  name: "user",
  initialState: {
    loading: false,
    isLoggedIn: false,
    profile: {
      _id: null,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    // you can mutate state directly, since it is using immer behind the scenes
    builder
      .addCase(getProfile.pending, (state, action) => {
        state.loading = true;
        state.isLoggedIn = false;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.data && action.payload.data.userid) {
          state.profile = action.payload.data.userid;
          state.isLoggedIn = true;
        } else if (cookies.get("SID")) {
          cookies.remove("SID");
          window.location.reload()
        }
      })
      .addCase(getProfile.rejected, (state, action) => {
        state.loading = false;
        state.isLoggedIn = false;
      });
  },
});
export default loginSlice.reducer;
