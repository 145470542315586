import { createAsyncThunk } from "@reduxjs/toolkit";
import apiRequest from "../../services/auth";

export const getProfile = createAsyncThunk(
  "user/get",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: "/user/userinfo",
      method: "GET",
    }).then((response) => {
      return response;
    });
    return res;
  }
);

export const getStacks = createAsyncThunk(
  "user/get/staked",
  async (data, { dispatch }) => {
    // const location = data.data.location;
    const res = await apiRequest({
      url: `/staked?location=${data.location}`,
      method: "GET",
    }).then((response) => {
      return response;
    });
    return res;
  }
);

export const getAllWaxAssets = createAsyncThunk(
  "user/getAllWaxAssets",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      baseURL: `https://wax.api.atomicassets.io/atomicassets/v1/assets?collection_name=dragonsonwax&schema_name=&template_id=${data.templateIds}&owner=${data.username}&page=1&limit=400&order=desc&sort=asset_id`,
      method: "GET",
    }).then((response) => {
      dispatch(getStacks(data));
      return response;
    });
    return res;
  }
);

export const addStack = createAsyncThunk(
  "user/add/stake",
  async (data, { dispatch }) => {
    const { location, ...rest } = data;
    const res = await apiRequest({
      url: `/stake/${rest.templateId}`,
      method: "POST",
      data: rest,
      skipNotification: true,
    }).then((response) => {
      dispatch(getStacks({ location }));
      return response;
    });
    return res;
  }
);

export const unStack = createAsyncThunk(
  "user/remove/stack",
  async (data, { dispatch }) => {
    const { location, ...rest } = data;

    const res = await apiRequest({
      url: "/unstake",
      method: "POST",
      data: rest,
    }).then((response) => {
      dispatch(getStacks({ location }));
      return response;
    });
    return res;
  }
);

export const sendToken = createAsyncThunk(
  "user/token",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: "/token",
      method: "POST",
      data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);

export const getToken = createAsyncThunk(
  "user/token",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: "/token",
      method: "POST",
    }).then((response) => {
      return response;
    });
    return res;
  }
);
